import { KikRestVerbs } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { HttpService } from "~/services/http";
import Logger from "~/services/logger/logger";
import { ApiConstants } from "~/shared/api/api-constants.class";
import { ErrorResponse } from "~/shared/api/error-response.class";
import { CheckBalanceRequest, CheckBalanceResponse } from "~/shared/giftcard/check-balance.model";
import Constants from "~/utils/constants";

const useGiftcard = () => {
  const [giftcardServiceError, setGiftcardServiceError] = useState<ErrorResponse | undefined>();
  const [giftcardChecksPending, setGiftcardChecksPending] = useState<boolean>(!1);

  // Get eva endpoint state from recoil
  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);
  // Use Recoil hooks for retrieving digital gift cards and checking their availability
  const GetDigitalGiftCardsForOrder = hooks.useCallService({ service: Core.GetDigitalGiftCardsForOrder });

  // PUBLIC METHODS

  async function doCheckGiftcardBalance(request: CheckBalanceRequest): Promise<CheckBalanceResponse | void> {
    setGiftcardServiceError(void 0);
    setGiftcardChecksPending(!0);
    try {
      const response = await HttpService.$http<EVA.Core.CardBalanceCheckResponse>({
        url: ApiConstants.endpointsV2.giftcardCheckBalance,
        method: KikRestVerbs.POST,
        body: request,
      });

      if (!response || response?.Error) {
        Logger.instance.warn("use-giftcard: giftcardCheckBalance errored", response);
        setGiftcardServiceError(ErrorResponse.build());
        setGiftcardChecksPending(!1);

        return void 0;
      }

      setGiftcardChecksPending(!1);
      return response;
    } catch (e: any) {
      // Here we receive the wrong PIN error
      Logger.instance.warn("use-giftcard: giftcardCheckBalance error caught", e);
      setGiftcardServiceError(e.body || ErrorResponse.build());
      setGiftcardChecksPending(!1);
    }
  }

  function prepareGetDigitalGiftcardOptionsQuery(cartOrOrder?: EVA.Core.OrderDto) {
    const hasDigitalGiftCards = cartOrOrder?.Lines.some((line) => !line.IsCancelled && line.IsDigitalGiftCard);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery({
      queryKey: ["digitalGiftCardsDetails", cartOrOrder?.ID],
      staleTime: Constants.DEFAULT_STALE_TIME,
      queryFn: () => {
        return GetDigitalGiftCardsForOrder(
          // @ts-expect-error
          { OrderID: `${cartOrOrder?.ID}` }
        ).then((r) => r?.Result);
      },
      enabled: !!evaEndpointUrl && !!cartOrOrder?.ID && hasDigitalGiftCards,
    });
  }

  return {
    doCheckGiftcardBalance,
    giftcardChecksPending,
    giftcardServiceError,
    prepareGetDigitalGiftcardOptionsQuery,
  };
};

export default useGiftcard;
