import { Entry } from "contentful";
import { CSSProperties, Key } from "react";

import Grid from "~/components/common/grid";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import Container from "../common/container";
import Icon from "../common/icon";
import styles from "./prefooter.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function Prefooter(props: Props) {
  if (!props?.entry) {
    return null;
  }

  assertEditorialType<Editorials.Prefooter>(props.entry, "prefooter");

  return (
    <Container className={styles.container}>
      <Grid
        className={styles.gridContainer}
        style={{ "--number-icons": props.entry.fields.iconList?.filter(isActiveEntry).length * 2 } as CSSProperties}
      >
        {props.entry.fields.iconList?.filter(isActiveEntry).map((icon, index) => {
          const { svgIconId, label, svgMedia } = icon.fields;
          const inspectorMode = contentfulUtils.useInspectorMode(icon);
          return (
            <div key={`${svgIconId}-Prefooter-${index}`} className={styles.containerIllustration}>
              <Icon svgMedia={svgMedia} name={svgIconId} className={styles.illustrationIcon} />
              <span className={styles.illustrationLabel} {...inspectorMode?.getProps("label")}>
                {label}
              </span>
            </div>
          );
        })}
      </Grid>
    </Container>
  );
}
